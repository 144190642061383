/* eslint-disable max-len */
import React, {FC} from 'react';
import Layout from '../layouts/Primary';
import SubHeader from '../components/SubHeader';

const Page: FC = () =>
	<Layout>
		<SubHeader
			title="Terms of Use"
		/>
		<div className="ex-basic-2">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="text-container">
							<h3>Limitations Of Liability</h3>
							<h6>Updated July 2021</h6>
							<p>BeamFire collects and receives certain information from your
							organization to complete the activities you perform on our Website, the Platforms, and the
							Applications. Any connection to Websites under
							test are managed by subscribing users that provide DNS level verification per domain. Any
							damages against active or production websites are not our liability.
							</p>
						</div>

						<div className="text-container">
							<h3>Terms and Conditions</h3>
							<p>Under no circumstances shall BeamFire be liable for any direct, indirect, special,
							incidental or
							consequential damages, including, but not limited to, loss of data or profit, arising out of
							the use, or the inability to use, the materials on this site, even if BeamFire or an
							authorized
							representative has been advised of the possibility of such damages. If your use of materials
							or services
							from this site results in the need for servicing, repair or correction of equipment or data,
							you assume any costs thereof should only be provided by the user of the application.</p>
						</div>

						<div className="text-container last">
							<h3>Terms of Service</h3>
							<p>By using the beamfire.io web site (&quot;Service&quot;), or any services of BeamFire
							(&quot;BeamFire&quot;), you are agreeing to be bound by the following terms and
							conditions
							(&quot;Terms of Service&quot;). IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF A
							COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH
							ENTITY, ITS AFFILIATES AND ALL USERS WHO ACCESS OUR SERVICES THROUGH YOUR ACCOUNT TO THESE
							TERMS AND CONDITIONS, IN WHICH CASE THE TERMS &quot;YOU&quot; OR &quot;YOUR&quot; SHALL
							REFER TO SUCH ENTITY, ITS AFFILIATES AND USERS ASSOCIATED WITH IT. IF YOU DO NOT HAVE SUCH
							AUTHORITY, OR IF YOU DO NOT AGREE WITH THESE TERMS AND CONDITIONS, YOU MUST NOT ACCEPT THIS
							AGREEMENT AND MAY NOT USE THE SERVICES. </p>
							<p>If BeamFire makes material changes to these Terms, we will notify you by email or by posting
							a
							notice on our site before the changes are effective. Any new features that augment or
							enhance the current Service, including the release of new tools and resources, shall be
							subject to the Terms of Service. Continued use of the Service after any such changes shall
							constitute your consent to such changes. You can review the most current version of the
							Terms of Service at any time at: <a href="/terms-of-use/">
									beamfire.io/terms-of-use/
							</a>
							</p>
							<p>Violation of any of the terms below will result in the termination of your Account. While
							BeamFire prohibits such conduct and Content on the Service, you understand and agree
							that
							BeamFire cannot be responsible for the Content posted on the Service and you
							nonetheless may
							be exposed to such materials. You agree to use the Service at your own risk.</p>
							<ol>
								<li>
									<p>You must be 13 years or older to use this Service.</p>
								</li>
								<li>
									<p>You must be the controlling administrator of each website under test at the domain&apos;s
									DNS level. All audits are required to first pass a DNS authentication test using a
									provide TXT value for the domain.</p>
								</li>
								<li>
									<p>You must be a human. Accounts registered by &quot;bots&quot; or other automated
									methods are not permitted.</p>
								</li>
								<li>
									<p>You must provide your legal full name, a valid email address, and any other
									information requested in order to complete the signup process.</p>
								</li>
								<li>
									<p>Your login may only be used by one person - a single login shared by multiple people
									is not permitted. You may create separate logins for as many people as your plan
									allows.</p>
								</li>
								<li>
									<p>You are responsible for maintaining the security of your account and password.
									BeamFire
									cannot and will not be liable for any loss or damage from your failure to comply
									with this security obligation.</p>
								</li>
								<li>
									<p>You are responsible for all Content posted and activity that occurs under your
									account (even when Content is posted by others who have accounts under your
									account).</p>
								</li>
								<li>
									<p>One person or legal entity may not maintain more than one free account.</p>
								</li>
								<li>
									<p>You may not use the Service for any illegal or unauthorized purpose. You must not, in
									the use of the Service, violate any laws in your jurisdiction (including but not
									limited to copyright or trademark laws).</p>
								</li>
								<li>
									<p>All paid plans must enter a valid credit card. Free accounts are not required to
									provide a credit card number.</p>
								</li>
								<li>
									<p><strong>An upgrade from the free plan to any paying plan will immediately bill
										you.</strong>
									</p>
								</li>
								<li>
									<p>For monthly payment plans, <strong>the Service is billed in advance on a monthly
									basis and is non-refundable. There will be no refunds or credits for partial
									months of service, upgrade/downgrade refunds, or refunds for months unused with
									an open account. In order to treat everyone equally, no exceptions will be
										made.</strong></p>
								</li>
								<li>
									<p>
										<strong>When changing from a monthly billing cycle to a yearly billing
										cycle,
										BeamFire will bill for a full year at the next monthly billing date.</strong>
									</p>
								</li>
								<li>
									<p>All fees are exclusive of all taxes, levies, or duties imposed by taxing authorities,
									and you shall be responsible for payment of all such taxes, levies, or duties,
									excluding only United States (federal or state) taxes.</p>
								</li>
								<li>
									<p>For any upgrade or downgrade in plan level while on a monthly billing cycle, the
									credit card that you provided will automatically be charged the new rate on your
									next billing cycle.<br /> For upgrades or downgrades while on a yearly plan,
									BeamFire
									will immediately charge or refund the difference in plan cost, prorated for the
									remaining time in your yearly billing cycle.</p>
								</li>
								<li>
									<p>Downgrading your Service may cause the loss of Content, features, or capacity of your
									Account. BeamFire does not accept any liability for such loss.</p>
								</li>
								<li>
									<p><strong>You are solely responsible for properly canceling your account. An email or
									phone request to cancel your account is not considered cancellation. You can
									cancel
									your account at any time by clicking on the Account link in the global
									navigation
									bar at the top of the screen. The Account screen provides a simple no questions
										asked cancellation link.</strong></p>
								</li>
								<li>
									<p>All of your Content will be immediately deleted from the Service upon cancellation.
									This information can not be recovered once your account is cancelled.</p>
								</li>
								<li>
									<p>If you cancel the Service before the end of your current paid up month, your
									cancellation will take effect immediately and you will not be charged again.</p>
								</li>
								<li>
									<p>BeamFire, in its sole discretion, has the right to suspend or terminate your
									account
									and refuse any and all current or future use of the Service, or any other BeamFire
									service, for any reason at any time. Such termination of the Service will result in
									the deactivation or deletion of your Account or your access to your Account, and the
									forfeiture and relinquishment of all Content in your Account. BeamFire
									reserves the
									right to refuse service to anyone for any reason at any time.</p>
								</li>
								<li>
									<p>Your use of the Service is at your sole risk. The service is provided on an &quot;as
									is&quot; and &quot;as available&quot; basis.</p>
								</li>
								<li>
									<p>Support for BeamFire services is only available in English, via email.</p>
								</li>
								<li>
									<p>You understand that BeamFire uses third party vendors and hosting partners to
									provide
									the necessary hardware, software, networking, storage, and related technology
									required to run the Service.</p>
								</li>
								<li>
									<p>You must not modify, adapt or hack the Service or modify another website so as to
									falsely imply that it is associated with the Service, BeamFire, or any other
									BeamFire
									service.</p>
								</li>
								<li>
									<p>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of
									the Service, use of the Service, or access to the Service without the express
									written permission by BeamFire.</p>
								</li>
								<li>
									<p>Verbal, physical, written or other abuse (including threats of abuse or retribution)
									of any BeamFire customer, employee, member, or officer will result in
									immediate
									account termination.</p>
								</li>
								<li>
									<p>You understand that the technical processing and transmission of the Service,
									including your Content, may be transferred unencrypted if your Websites are not
									using TLS and involve (a) transmissions
									over various networks; and (b) changes to conform and adapt to technical
									requirements of connecting networks or devices.</p>
								</li>
								<li>
									<p>You must not transmit any worms or viruses or any code of a destructive nature.</p>
								</li>
								<li>
									<p>BeamFire does not warrant that (i) the service will meet your specific
									requirements,
									(ii) the service will be uninterrupted, timely, secure, or error-free, (iii) the
									results that may be obtained from the use of the service will be accurate or
									reliable, (iv) the quality of any products, services, information, or other material
									purchased or obtained by you through the service will meet your expectations, and
									(v) any errors in the Service will be corrected.</p>
								</li>
								<li>
									<p>You expressly understand and agree that BeamFire shall not be liable for any
									direct,
									indirect, incidental, special, consequential or exemplary damages, including but not
									limited to, damages for loss of profits, goodwill, use, data or other intangible
									losses (even if BeamFire has been advised of the possibility of such
									damages),
									resulting from: (i) the use or the inability to use the service; (ii) the cost of
									procurement of substitute goods and services resulting from any goods, data,
									information or services purchased or obtained or messages received or transactions
									entered into through or from the service; (iii) unauthorized access to or alteration
									of your transmissions or data; (iv) statements or conduct of any third party on the
									service; (v) or any other matter relating to the service.</p>
								</li>
								<li>
									<p>The failure of BeamFire to exercise or enforce any right or provision of the
									Terms of
									Service shall not constitute a waiver of such right or provision. The Terms of
									Service constitutes the entire agreement between you and BeamFire and govern
									your use
									of the Service, superseding any prior agreements between you and BeamFire
									(including,
									but not limited to, any prior versions of the Terms of Service). You agree that
									these Terms of Service and Your use of the Service are governed under California
									law.</p>
								</li>
								<li>
									<p>Questions about the Terms of Service should be sent to&nbsp;
										<a href="mailto:contact@beamfire.io"
											rel="noreferrer"
											target="_blank">
											contact@beamfire.io</a>.
									</p>
								</li>
							</ol>
							<a className="btn-outline-reg"
								href="/">BACK</a>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className="ex-basic-1">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="breadcrumbs">
							<a href="/">Home</a><i className="fa fa-angle-double-right" /><span>Terms &amp;
							Conditions</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>;

export default Page;
